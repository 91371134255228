@import 'src/assets/styles/variables';

//start user registration
.dashboard-user-registration {
    .title {
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
        color: #006cb6;
    }

    .return-btn-col {
        text-align: right !important;
    }
    .return-btn {
        width: 180px !important;
        border: 1px solid #006cb6 !important;
        border-radius: 27px !important;
        padding: 8px 32px 32px !important;

        .return-btn-text {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            text-align: center;
            color: #006cb6;
        }

        .btn-icon {
            font-size: 14px;
            padding-right: 10px;
        }
    }

    .note {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        color: #2e353a;
        margin-top: 32px !important;
        margin-bottom: 32px !important;
    }

    .user-registration-div-top {
        padding: 20px !important;
        border-top: 1px solid #d4e1ea;
        border-bottom: 1px solid #d4e1ea;
    }

    .user-registration-div {
        padding: 20px !important;
        border-bottom: 1px solid #d4e1ea;
    }

    .user-registration-row {
        align-items: center !important;
    }

    .user-registration-label {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        text-align: left;
        color: #2e353a;
        border-right: 1px solid #d4e1ea;
    }

    .user-registration-input {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        text-align: left;
        color: #006cb6;
        padding-left: 32px;

        .fullname-row {
            display: flex;
            gap: 27px;
        }

        .ant-form-item {
            margin-bottom: 0px !important;
        }

        .ant-checkbox-wrapper,
        .ant-radio-wrapper {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            text-align: left;
            color: #2e353a;
            padding-right: 20px;
        }
    }

    .ant-input {
        border-radius: 4px !important;
    }

    .ant-space,
    .ant-select {
        width: 100% !important;
    }

    .register-btn-div {
        margin-top: 40px !important;
        text-align: center;
    }

    .register-btn {
        width: 30% !important;
        border-radius: 27px !important;
        padding: 15px 32px 35px !important;
        background: #006cb6 !important;

        .register-btn-text {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            text-align: center;
            color: #ffffff;
            letter-spacing: 1px;
        }
    }
}

//modal
.registration-confirmation-modal {
    width: 40% !important;

    .title {
        font-size: 32px;
        line-height: 48px;
        font-weight: 700;
        text-align: center;
        color: #2e353a;
        padding: 32px 16px;
    }

    .subtitle {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        text-align: center;
        color: #2e353a;
        text-align: center;
    }
}

//end user registration
