body#dashboard {
    @import 'src/assets/styles/mobile/components/consultation';
    @import 'src/assets/styles/mobile/components/meeting_calendar';
    @import 'src/assets/styles/mobile/components/user_details';
    @import 'src/assets/styles/mobile/components/meeting_list';

    .ant-layout-content {
        margin-left: 0;
        padding: 15px;
    }

    .ant-layout-header {
        margin-left: 0;
        width: 100%;
    }

    .ant-layout-sider {
        z-index: 1000;
    }

    .dashboard-content.profile {
        .form-container {
            width: 100%;
        }
    }

    .header-container {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .page-actions {
        margin-left: 0;
    }

    .header-container {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .page-actions {
        margin-left: 0;
    }
    
    .dashboard-sider {
        overflow: unset;
        position: fixed;
        height: calc(100vh - 75px);
        left: 0;
        z-index: 101;

        &.sider-menu {
            &.mobile {
                background-color: #fff;

                .menu-1 {
                    height: auto;
                }
            }
        }
    }

    .dashboard-layout-content {
        padding: 135px 0px 50px 0px;
    }

    .ant-layout-sider-zero-width-trigger {
        top: 0;
    }

    .user-header {
        display: block;
    }
    .user-counter {
        text-align: start !important;
    }

    .button-right {
        margin-top: 10px;
    }
}
