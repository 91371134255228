.meeting-calendar {
    .month-picker-text {
        font-size: 14px;
    }

    &-info {
        .ant-picker-body {
            overflow-x: scroll;

            table {
                min-width: 600px;
            }
        }

        .meeting-calendar-header {
            .meeting-calendar-formats {
                .calandar-options {
                    font-size: 10px;
                }
            }

            .calendar-header-info {
                height: auto;

                .month-picker, .schedule-meeting-count {
                    position: relative;
                    justify-content: center !important;
                }
            }
        }
    }
}
