@import 'src/assets/styles/variables';

body#dashboard {
    @import 'src/assets/styles/desktop/components/registration_card';
    @import 'src/assets/styles/desktop/components/meeting_calendar';
    @import 'src/assets/styles/desktop/components/consultation';
    @import 'src/assets/styles/desktop/components/diagnose';
    @import 'src/assets/styles/desktop/components/user_details';
    @import 'src/assets/styles/desktop/components/user_management';
    @import 'src/assets/styles/desktop/components/user_registration';
    @import 'src/assets/styles/desktop/components/meeting_list';
    @import 'src/assets/styles/desktop/components/meeting_details';
    @import 'src/assets/styles/desktop/components/meeting_details_modal';

    background: $white;

    .ant-layout {
        min-height: 100vh;
    }

    .down-icon {
        line-height: 24px;
        float: right;
        font-size: 14px;
    }

    .ant-layout-sider {
        position: fixed;
        height: 100vh;

        background-color: #1b2226;

        .ant-menu {
            background: none;
        }

        .ant-menu-item:first-child {
            margin-top: 0;
        }
    }

    .ant-layout-header {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
    }

    .dashboard-content-right {
        margin-left: 300px;
    }

    .ant-layout-content {
        background-color: $white;
    }

    .sider-title,
    .ant-menu-item,
    .ant-menu-item a {
        color: $gray-2;
    }

    .dashboard-sider {
        overflow: auto;

        .menu-item,
        .menu-group-item {
            color: #2e353a;
        }

        .menu-item {
            padding-left: 16px !important;
        }

        &.sider-menu {
            background-color: transparent;

            .menu-layout {
                height: auto;
                background-color: white;
                padding: 16px;
                border-radius: 4px;
                margin-top: 10px !important;

                .menu-group-item-link {
                    color: #2e353a;

                    &.active {
                        color: #006cb6;
                    }
                }
            }

            .menu-2 {
                margin-top: 16px;
            }
        }
    }

    .menu-group {
        .ant-menu-submenu-title {
            padding-left: 16px !important;
        }

        .menu-group-title {
            color: #2e353a;
            display: flex;
            align-items: center;

            .menu-group-title-text {
                margin-left: 10px;
            }
        }

        .menu-group-item {
            padding-left: 44px !important;

            .menu-group-item-content {
                display: flex;
                align-items: center;
            }
        }
    }

    .sider-title {
        font-size: 20px;
        height: 64px;
        line-height: 64px;
        text-align: center;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .ant-menu {
        color: #ffffff;
    }

    .ant-menu-item-selected {
        background-color: #f2f7fb;

        &,
        a {
            color: #fff;
        }

        &:after {
            border: none;
        }

        &.menu-item {
            color: #006cb6;
        }
    }

    .ant-menu-inline {
        border-right: none;
    }

    .ant-menu-item {
        width: 100%;

        & :after {
            right: 1px;
        }
    }

    .ant-layout-header {
        background: #006bb5;

        .buttons {
            float: right;

            button {
                border: none;
                background: none;
                color: $white;
            }
        }
    }

    .ant-layout-content {
        padding: 32px;
    }

    .sharoushi-registration-card {
        width: 100%;
    }

    .breadcrumb-nav {
        position: fixed !important;
        top: 100px !important;
        left: 175px !important;
        font-size: 14px !important;
        line-height: 21px !important;
        font-weight: 400 !important;
        color: #000000d9 !important;
    }

    .dashboard-layout-content {
        padding: 135px 175px 75px;
        flex-direction: row;
    }

    .dashboard-content {
        .title {
            font-size: 20px;
            font-weight: bold;
        }

        .content {
            background-color: #fff;
            padding: 20px;
        }

        .section-title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .change-password {
            color: #01a0dd;
            text-decoration: underline;
            margin-bottom: 45px;
            cursor: pointer;
        }

        .card-container {
            display: flex;
            padding: 15px;
        }

        .ant-input-search-button {
            border: 1px solid $gray-3 !important;
            background-color: white;
        }

        .card-container,
        .card-element {
            width: 100%;
            border-radius: 3px;

            &.dark-gray {
                border: 1px solid $gray-4;
                background: rgba(196, 196, 196, 0.4);
                color: $gray-4;
            }

            &.purple {
                border: 1px solid $purple-3;
                background: $purple-1;
                color: $purple-3;
            }

            &.gray {
                border: 1px solid $gray-3;
                background: $gray-2;
            }

            &.light-blue {
                border: 1px solid $light-blue-2;
                background: $light-blue-1;
            }

            &.orange {
                border: 1px solid $orange-3;
                background: $orange-1;
            }

            &.green {
                border: 1px solid $green-2;
                background: $green-1;
            }

            &.red {
                border: 1px solid $red-2;
                background: $red-1;
            }

            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        .custom-table {
            .action-buttons {
                float: none;

                .ant-btn {
                    min-width: 100px;

                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }

                .edit-button {
                    background: $light-blue-1;
                    color: $button-primary-color;
                    border: 1px solid $button-primary-color;
                }

                button.delete-button {
                    background: $red-1;
                    color: $red-3;
                    border: 1px solid $red-3;
                }
            }

            .ant-table {
                background: none;
            }

            .ant-table-thead > tr > th {
                border-bottom: 2px solid $gray-6;
                background: none;
            }

            .ant-table-thead
                > tr
                > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
                display: none;
            }

            .ant-table-tbody > tr > td {
                border-bottom: none;
                padding: 5px 16px;
            }

            &.highlighted .ant-table-row {
                background-color: $gray-1;
                border: 1px solid rgba(196, 196, 196, 0.4);
            }
        }
    }

    .user-management {
        .title {
            font-size: 24px;
            font-weight: 700;
            line-height: 36px;
            color: #006cb6;
            margin-bottom: 45px;
        }

        .user-header {
            display: flex;
            justify-content: space-between;
        }

        .subtitle {
            padding-top: 10px !important;
            font-size: 15px !important;
        }

        .search-input {
            padding-top: 6px;
        }

        .search > .ant-input-wrapper > .ant-input {
            height: 32px !important;
        }

        .user-counter {
            text-align: right;
            margin-right: 20px;
        }

        .add-user-button {
            padding-left: 30px;
            padding-right: 30px;
            height: 40px;
            border-radius: 50px !important;
        }

        .plus-sign {
            font-size: 18px;
            margin-right: 5px;
            padding-bottom: 10px;
        }

        .button-right {
            margin-left: auto;
            text-align: right;
        }

        &-table {
            .ant-table {
                overflow-x: scroll !important;

                .ant-table-container {
                    min-width: 800px !important;
                }

                table {
                    border-spacing: 0 1em !important;
                }

                .ant-table-thead {
                    .ant-table-cell {
                        background-color: transparent !important;

                        &::before {
                            height: 0 !important;
                        }
                    }

                    & > tr > th {
                        border-bottom: 0 !important;
                    }
                }

                .ant-table-tbody {
                    tr > td:nth-child(-n + 6) {
                        border-top: 1px solid rgba(212, 225, 234, 1) !important;
                        border-bottom: 1px solid rgba(212, 225, 234, 1) !important;
                    }

                    & > tr.ant-table-measure-row {
                        display: none;
                    }

                    & > tr > td:first-child {
                        border-left: 1px solid rgba(212, 225, 234, 1) !important;
                        border-radius: 4px 0 0 4px !important;
                    }

                    & > tr > td:nth-child(6) {
                        border-right: 1px solid rgba(212, 225, 234, 1) !important;
                        border-radius: 0 4px 4px 0 !important;
                    }

                    & > tr > td:nth-child(7) {
                        border-bottom: none;
                    }

                    & > tr:hover > td:nth-child(7) {
                        background-color: transparent;
                    }
                }
            }

            .delete-user-icon {
                margin-left: 20px;
                color: #e95532;
                background-color: #fceeea;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                border: 2px solid #e95532;
                cursor: pointer;
            }

            .delete-user-icon:hover {
                margin-left: 20px;
                color: white;
                background-color: #e95532;
            }
        }
    }
    .profile {
        .edit-button {
            width: 100px;
            float: right;
        }

        .upload-preview {
            border: 1px solid #d9d9d9;
            border-radius: 5px;
            padding: 5px;
            height: 100px;
            min-width: 100px;
        }
    }

    .header-container {
        display: flex;
    }

    .page-actions {
        margin-left: auto;

        &-items {
            width: 250px;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    .comment {
        .ant-form-item-required::before {
            display: none !important;
        }
    }

    .consultation-details {
        &-in-charge {
            margin: 20px 0;

            .label {
                font-weight: bold;
                margin: 10px 0;
            }
        }

        .action-buttons {
            margin-top: 30px;

            &_cancel {
                margin-right: 20px;
                width: 200px;
            }

            &_send {
                width: 200px;
            }
        }
    }

    .dashboard-content.profile {
        .form-container {
            width: 30%;
        }
    }

    .header-container {
        display: flex;
    }

    .page-actions {
        margin-left: auto;

        &-items {
            width: 250px;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    .copyright-footer {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
        font-size: 12px;
        color: #2E353A;
    }
}

@media (max-width: 1366px) {
    body#dashboard {
        .dashboard-layout-content {
            padding: 135px 35px 100px 60px;
        }

        .ant-layout-content {
            padding: 25px;
        }
    }
}
