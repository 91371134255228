@import '~antd/dist/antd.css';
@import 'bootstrap';
@import 'variables';

@import 'desktop/main';

// pages
@import 'desktop/dashboard';
@import 'desktop/labor_social';

// components
@import 'desktop/components/labor_social_navbar';
@import 'desktop/components/dashboard_navbar';

// mobile responsive
@media all and (max-width: 480px) {
    @import 'mobile/main';
    @import 'mobile/dashboard';
    @import 'mobile/labor_social';

    @import 'mobile/components/labor_social_navbar';
}