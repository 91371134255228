.meeting-list {
    .title {
        color: #006cb6;
        font-family: Noto Sans JP;
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 25px;
    }

    .table-filter {
        float: right;
    }

    &-pagination {
        margin: 15px 0;
    }

    .blue-color-icon {
        color: #006cb6;

        &:hover {
            color: #01a0dd;
        }
    }

    .fs-14 {
        color: #2e353a;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
    }

    .fs-14-normal {
        color: #2e353a;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }

    .fs-12 {
        color: #2e353a;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
    }

    &-status {
        display: block;
        width: 100px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        padding: 4px 10px;
    }

    &-read-label {
        font-size: 12px;
        padding: 4px 8px;
    }

    &-search {
        width: 100%;
        display: flex;

        .search-title {
            display: flex;
            align-items: center;
            color: #2e353a;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            width: 75px;
        }

        .status-search {
            min-width: 270px;

            .meeting-select {
                width: 68%;

                .ant-select-selector {
                    height: 37px !important;
                    padding: 1px 11px !important;
                    background: rgba(250, 252, 253, 1);
                }

                .ant-select-selection-item {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .text-search {
            min-width: 472px;
            .meeting-search {
                width: 63.5%;

                .ant-input {
                    padding: 6.9px 12px !important;
                    background: rgba(250, 252, 253, 1);
                }

                .ant-input-search-button {
                    height: 37px !important;
                    background: rgba(250, 252, 253, 1);
                    border-left: none !important;
                    border-radius: 0 2px 2px 0 !important;
                }
            }
        }
    }

    .meeting-table {
        .status {
            &-matching {
                background: #fff5ec;
                border: 1px solid #ffd9aa;
                color: #f4a353;
            }

            &-selecting-specialist {
                background: #f1ebeb;
                border: 1px solid #b8aba3;
                color: #843c0c;
            }

            &-scheduled {
                background: #fcf1e6;
                border: 1px solid #e97306;
                color: #e97306;
            }

            &-finished {
                background: #ffffff;
                border: 1px solid #006cb6;
                color: #006cb6;
            }

            &-skipped {
                background: #f4f5f5;
                border: 1px solid #848f97;
                color: #848f97;
            }

            &-canceled {
                background: #ffffff;
                border: 1px solid #e95532;
                color: #e95532;
            }

            &-past-due {
                background: #ffffff;
                border: 1px solid #848f97;
                color: #848f97;
            }
        }
        .label {
            &-read {
                background: #ffffff;
                border: 1px solid #848f97;
                color: #848f97;
            }

            &-unread {
                background: #ffffff;
                border: 1px solid #e95532;
                color: #e95532;
            }
        }
        .ant-table {
            table {
                border-spacing: 0 1em !important;

                thead {
                    .ant-table-cell {
                        background-color: transparent !important;
                        font-weight: bold;

                        &::before {
                            height: 0 !important;
                        }
                    }
                    & > tr > th {
                        border-bottom: 0 !important;
                    }
                }

                tbody {
                    & > tr > td {
                        padding: 16px 10px;
                        border-top: 1px solid #d4e1ea !important;
                        border-bottom: 1px solid #d4e1ea !important;
                    }
                    & > tr.ant-table-measure-row {
                        display: none;
                    }
                    & > tr > td:first-child {
                        border-left: 1px solid #d4e1ea !important;
                        border-radius: 4px 0 0 4px !important;
                    }
                    & > tr > td:last-child {
                        border-right: 1px solid #d4e1ea !important;
                        border-radius: 0 4px 4px 0 !important;
                    }
                    & > tr {
                        cursor: pointer !important;
                    }
                }
            }
        }
    }

    &-pagination button,
    &-pagination li {
        border: none !important;
    }

    @media (max-width: 1280px) {
        &-search {
            flex-wrap: wrap;

            .status-search {
                min-width: 0;

                .meeting-select {
                    width: 55%;
                    min-width: 120px;
                }
            }

            .text-search {
                min-width: 0;

                .meeting-search {
                    width: 63.5%;
                    width: 220px;
                }
            }
        }
    }
}
