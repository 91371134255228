.meeting-calendar {
    .calendar-header {
        align-items: center;
        padding: 10px;
    }

    .count {
        margin-left: 10px;
    }

    .month-picker {
        text-align: right;

        &-text {
            font-weight: bold;
            font-size: 16px;
        }

        .ant-btn {
            border: none;
            color: black;
        }
    }

    .hojokin-alert {
        background-color: #fffbe6;
        border: 1px solid #ffe58f;
    }

    .josekin-alert {
        background-color: #f8a5c2;
        border: 1px solid #f78fb3;
    }

    .kisoku-alert {
        background-color: #b8e994;
        border: 1px solid #78e08f;
    }

    .meeting-notifications-container .ant-alert-message > a {
        color: #000;
    }

    .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        color: #006cb6;
        margin-bottom: 45px;
    }

    .notification {
        &-container {
            padding: 16px 0px 16px 16px;
            border-radius: 3px;
            border: 1px solid #d4e1ea;
            margin-bottom: 15px;

            &_read {
                color: #a2adb4;
                background-color: #f4f5f5;
            }

            &_unread {
                background-color: #ffffff;
            }
        }

        &-row {
            align-items: center;
        }

        &-date {
            font-size: 14px;
            line-height: 21px;
            font-weight: 700;
            text-align: center;
        }

        &-title {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
        }

        &-btn-icon {
            color: #006cb6;
            font-size: 30px;
        }
    }

    .notification-container {
        cursor: pointer;

        .notification-title {
            &-status {
                color: #e97306;
            }
        }

        .notification {
            &-status-scheduled {
                color: #e97306;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }

            &-status-finished {
                color: #006cb6;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }

            &-status-skipped {
                color: #848f97;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }

            &-status-cancelled {
                color: #848f97;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
            }
        }
    }
    
    &-specialist {
        margin-top: 32px;
    }

    &-info {
        .meeting-calendar-header {
            margin-bottom: 7px;

            .meeting-calendar-formats {
                display: flex;
                justify-content: space-between;
                margin-top: 30px;

                .calandar-options {
                    width: 100%;
                    padding: 10px;
                    height: auto !important;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 21px;
                    text-align: center;
                    border-radius: 4px !important;
                    color: #006cb6;
                    border: 0.8px solid #d4e1ea;
                    box-shadow: 0px 0px 8px 0px #00000026;
                    transition: 0.3s ease-in-out;

                    &:hover {
                        opacity: 0.7;
                        border: 1px solid #006cb6;
                        transition: 0.3s ease-in-out;
                    }

                    &:first-child {
                        margin-right: 5px;
                    }

                    &:last-child {
                        margin-left: 5px;
                    }
                }

                .active {
                    background-color: #006cb6 !important;
                    color: #ffffff !important;
                }
            }

            .calendar-header-info {
                position: relative;
                width: 100%;
                height: 30px;

                .month-picker {
                    width: 100%;
                    position: absolute;
                    text-align: center !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &-text {
                        margin: 0 1rem;
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 30px;
                    }

                    .picker-arrow {
                        color: #006cb6;
                        padding: 6px;
                        border: 1.5px solid #006cb6;
                        border-radius: 4px !important;
                        height: auto !important;
                        transition: 0.3s ease-in-out;

                        span {
                            display: flex;
                        }

                        &:hover {
                            background-color: #006cb6;
                            color: #ffffff;
                            transition: 0.3s ease-in-out;
                        }
                    }
                }

                .schedule-meeting-count {
                    position: absolute;
                    height: 30px;
                    right: 0;
                    display: flex;
                    justify-content: right;
                    align-items: center;

                    .count {
                        margin-left: 16px;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;

                        .confirmed-schedule {
                            color: #006cb6;
                        }

                        .available-schedule {
                            color: #e97306;
                        }

                        .not-available-schedule {
                            color: #e95532;
                        }
                    }
                }
                @media (max-width: 1440px) {
                    .month-picker {
                        &-text {
                            font-size: 15px;
                            margin: 0 0.6rem;
                        }

                        .picker-arrow {
                            padding: 4px;
                        }
                    }

                    .schedule-meeting-count {
                        .count {
                            font-size: 10px;
                            margin-left: 10px;
                            margin-top: 3px;
                        }
                    }
                }
                @media (max-width: 1024px) {
                    .month-picker {
                        justify-content: left;
                    }
                }
            }
        }

        .schedule-calendar {
            & > div > div > div > table > thead > tr > th {
                border: 1px solid #cccccc;
                text-align: center;
            }

            & > div > div > div > table > thead > tr > th:first-child {
                color: #e95532;
            }

            & > div > div > div > table > thead > tr > th:nth-child(7) {
                color: #006cb6;
            }

            & .ant-picker-panel {
                .ant-picker-body th {
                    padding: 5px !important;
                }

                .meeting-calendar-date {
                    border-top: none !important;
                    padding: 0 8px 5px 8px !important;
                    margin: 0 !important;

                    &::before {
                        display: none;
                    }
                }

                .ant-picker-cell-selected {
                    .meeting-calendar-date {
                        background: #8bbdff29;
                    }

                    & .ant-picker-calendar-date-today {
                        background-color: #f8fade;
                    }
                }

                .calendar-blue {
                    background-color: #e5f0f7;
                }

                .calendar-pink {
                    background-color: #fdf6f4;
                }

                .calendar-white {
                    background-color: #ffffff;
                }
            }

            & > div > div > div > table > tbody > tr > td {
                border: 1px solid #cccccc;
            }

            & > div > div > div > table > tbody > tr > td:first-child {
                color: #f6bbad !important;
            }

            & .ant-picker-cell-disabled:last-child {
                background-color: #f4f5f5;
                color: #99c4e1;
            }

            & .ant-picker-cell-disabled {
                background-color: #f4f5f5;
                color: #A2ADB4;
            }

            .white-meeting {
                background-color: #ffffff;
            }

            .pink-meeting {
                background-color: #fdf6f4;
            }

            .blue-meeting {
                background-color: #e5f0f7;
            }

            .grey-meeting {
                background-color: #f4f5f5;
                color: #f4f5f5;
            }

            & .meeting-calendar-date-value {
                text-align: start !important;
                padding-top: 5px;
            }

            & .meeting-calendar-date-content {
                .schedule-dashboard-calendar {
                    height: 100%;
                    padding: 0 !important;
                    text-align: start !important;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 15px;

                    .corp-name {
                        color: #006cb6;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            & .meeting-calendar-today {
                background-color: #f8fade !important;
            }
        }
    }
}

.schedule-meeting-dashboard-calendar-popup {
    max-width: 321px !important;

    .ant-tooltip-inner {
        min-width: 321px !important;
        padding: 16px !important;
    }

    .schedule-dashboard-calendar-popup {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;

        .confirmed-schedule {
            color: #006cb6;
        }

        .available-schedule {
            color: #e97306;
        }

        .not-available-schedule {
            color: #e95532;
        }

        .time {
            font-weight: 700;
        }

        .general-popup-style {
            margin-bottom: 0.5rem;
            color: #2e353a;

            &:last-child {
                margin-bottom: 0 !important;
            }
        }

        .blue-color-icon {
            font-size: 0.8rem !important;
            color: #006cb6;
        }

        .calendar-popup-blue {
            display: block;
            background: #f2f7fb !important;
            border: 1px solid #d5dee4 !important;
            border-radius: 4px !important;
            transition: 0.3s ease-in-out;

            &:hover {
                border: 1px solid #006cb6 !important;
                transition: 0.3s ease-in-out;
            }
        }

        .calendar-popup-light {
            background: #ffffff !important;
            border: 1px solid #d5dee4 !important;
            border-radius: 4px !important;
        }

        .calendar-popup-orange {
            background: #fdf6f4 !important;
            border: 1px solid #d5dee4 !important;
            border-radius: 4px !important;
        }
    }

    @media (max-width: 1280px) {
        max-width: 240px !important;

        .ant-tooltip-inner {
            min-width: 240px !important;
            padding: 8px !important;
        }

        .schedule-dashboard-calendar-popup {
            font-size: 9.5px;
        }
    }
}
