.user-list {
  &-delete-modal {
      .title-modal {
          color: #2e353a;
          font-size: 32px;
          font-weight: 700;
          line-height: 48px;
          text-align: center;
          margin: 40px 0;
      }

      .cancel-info {
          display: flex;
          color: #2E353A;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;

          &-description {
            width: 100%;
            margin-bottom: 16px;
          }
      }

      .form-buttons {
          display: flex;

          .general-button {
              border-radius: 27px !important;
              height: auto;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
          }

          .cancel-button {
              width: 200px;
              padding: 16px;
              background: transparent;
              color: #006cb6;
              border: 1px solid #006cb6;
              transition: ease-in-out 0.4s;

              &:hover {
                  background: #006cb6;
                  color: #ffffff;
                  transition: ease-in-out 0.4s;
              }
          }

          .submit-button {
              width: 320px;
              padding: 16px;
              color: #E95532;
              border: 1px solid #E95532;
              transition: ease-in-out 0.4s;

              &:hover {
                  opacity: 0.7;
                  transition: ease-in-out 0.4s;
              }
          }
      }
  }
}

