.user-details {
    .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        color: #006cb6;
    }

    .pagination {
        text-align: right !important;
    }

    .user-nav {
        padding-bottom: 30px !important;
    }

    .user-details-info {
        background-color: #f2f7fb;
        width: 100%;
        padding: 16px;
        margin-bottom: 30px;

        .desc {
            color: #2e353a;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;

            span {
                color: #006cb6;
            }
        }
    }

    .user-details-header {
        margin-bottom: 30px !important;
    }

    //start user detail page
    .user-detail-page {
        .return-btn-col {
            text-align: left !important;
        }
        .save-btn-col {
            text-align: center !important;
        }
        .return-btn {
            border: 1px solid #006cb6 !important;
            border-radius: 24px !important;
            padding: 12px 28px 36px !important;

            .return-btn-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                text-align: center;
                color: #006cb6;
            }

            .btn-icon {
                font-size: 14px;
                padding-right: 10px;
            }
        }

        .note {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            color: #2e353a;
            margin-top: 32px !important;
            margin-bottom: 32px !important;
        }

        .detail-page-div-top {
            padding: 20px !important;
            border-top: 1px solid #d4e1ea;
            border-bottom: 1px solid #d4e1ea;
        }

        .detail-page-div {
            padding: 20px !important;
            border-bottom: 1px solid #d4e1ea;
        }

        .detail-page-row {
            align-items: center !important;
        }

        .detail-page-label {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            text-align: left;
            color: #2e353a;
            border-right: 1px solid #d4e1ea;
        }

        .detail-page-value {
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            text-align: left;
            color: #2e353a;
            padding-left: 32px;

            .fullname-row {
                display: flex;
                gap: 27px;
            }

            .second-input {
                margin-left: 20px !important;
            }

            .ant-form-item {
                margin-bottom: 0px !important;
            }

            .ant-checkbox-wrapper,
            .ant-radio-wrapper {
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                text-align: left;
                color: #2e353a;
                padding-right: 20px;
            }
        }

        .ant-input {
            border-radius: 4px !important;
        }

        .ant-space,
        .ant-select {
            width: 100% !important;
        }

        .edit-btn-div {
            margin-top: 40px !important;
            text-align: left;
            margin-left: 20px !important;
        }

        .edit-btn {
            width: 50% !important;
            border-radius: 27px !important;
            padding: 12px 24px 36px !important;
            background: #006cb6 !important;

            .edit-btn-text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 700;
                text-align: center;
                color: #ffffff;
                letter-spacing: 1px;
            }
        }
    }

    //end user detail page

    &-header {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .specialist-options {
            width: 16%;
            min-width: 136px;
            margin-right: 12px;
            padding: 10px;
            height: auto !important;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            border-radius: 4px !important;
            color: #006cb6;
            border: 0.8px solid #d4e1ea;
            box-shadow: 0px 0px 8px 0px #00000026;
            transition: 0.3s ease-in-out;

            &:hover {
                opacity: 0.7;
                border: 1px solid #006cb6;
                transition: 0.3s ease-in-out;
            }

            &:last-child {
                margin-right: 0px;
            }
        }

        .disabled-link {
            pointer-events: none;
            color: #cccccc;
            text-decoration: none;
            opacity: 0.6;
        }

        .active {
            background-color: #006cb6 !important;
            color: #ffffff !important;
            font-weight: 700;
        }
    }

    .sharoushi {
        justify-content: space-between !important;
    }

    &-calendar {
        text-align: center;

        &-info {
            .corp-user-schedule {
                color: #197abd;
            }

            .specialists-schedule {
                color: #109636;
            }

            .reserved-schedule {
                color: transparent;
                background: #006cb6;
                border: 0.5px solid #006cb6;
            }

            .presented-schedule {
                color: transparent;
                background: #109636;
                border: 0.5px solid #109636;
            }

            .available-schedule {
                color: transparent;
                background: #ffffff;
                border: 0.5px solid #d5dee4;
            }

            .not-available-schedule {
                color: #f4f5f5;
                background: #f4f5f5;
                border: 0.5px solid #d5dee4;
            }

            .user-details-calendar-header {
                margin-bottom: 15px;

                .calendar-header-info {
                    position: relative;
                    width: 100%;
                    height: 30px;

                    .month-picker {
                        width: 100%;
                        position: absolute;
                        text-align: center !important;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &-text {
                            margin: 0 1rem;
                            font-size: 20px;
                            font-weight: 700;
                            line-height: 30px;
                        }

                        .picker-arrow {
                            color: #006cb6;
                            padding: 6px;
                            border: 1.5px solid #006cb6;
                            border-radius: 4px !important;
                            height: auto !important;
                            transition: 0.3s ease-in-out;

                            span {
                                display: flex;
                            }

                            &:hover {
                                background-color: #006cb6;
                                color: #ffffff;
                                transition: 0.3s ease-in-out;
                            }
                        }
                    }

                    .schedule-meeting-count {
                        position: absolute;
                        height: 30px;
                        right: 0;
                        display: flex;
                        justify-content: right;
                        align-items: center;

                        .count {
                            margin-left: 16px;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 18px;
                            margin-left: 10px;
                        }
                    }
                }
            }

            .user-details-schedule-calendar {
                & > div > div > div > table > thead > tr > th {
                    border: 1px solid #cccccc;
                    text-align: center;
                    width: 100%;
                }

                & > div > div > div > table > thead > tr > th:first-child {
                    color: #e95532;
                }

                & > div > div > div > table > thead > tr > th:nth-child(7) {
                    color: #006cb6;
                }

                // Change color of saturday date
                & > div > div > div > table > tbody > tr > td:nth-child(7) {
                    color: #99c4e1 !important;
                }
                // Make date cell height auto adjust depending on the content
                & .ant-picker-calendar-date-content {
                    height: auto;
                }

                // Ensures all datecell have equal height
                & .ant-picker-body tbody {
                    display: grid;
                    grid-template-rows: repeat(6, 1fr); 
                }
                // Ensures all datecell have equal height
                & .ant-picker-body tr {
                    display: grid;
                    grid-template-columns: repeat(7, 1fr);
                }
                
                & .ant-picker-calendar-date {
                    display: flex;
                    height: 100%;
                }

                & .ant-picker-panel {
                    .ant-picker-body {
                        overflow-x: scroll;
                        padding: 0 !important;

                        th {
                            padding: 5px !important;
                        }
                        table {
                            min-width: 860px;
                        }
                    }

                    .user-calendar-date {
                        display: flex;
                        justify-content: space-between;
                        border-top: none !important;
                        padding: 0 5px 5px 5px !important;
                        margin: 0 !important;
                        min-height: 138px;

                        &::before {
                            display: none;
                        }
                    }

                    .disabled-meeting-calendar {
                        min-height: 179px;
                    }

                    .ant-picker-cell-selected {
                        .user-calendar-date {
                            background: #8bbdff29;
                        }

                        & .ant-picker-calendar-date-today {
                            background-color: #f8fade;
                        }
                    }
                }

                & > div > div > div > table > tbody > tr > td {
                    border: 1px solid #cccccc;
                }

                & > div > div > div > table > tbody > tr > td:first-child {
                    color: #f6bbad !important;
                }

                & .ant-picker-cell-disabled {
                    background-color: #f4f5f5;
                }

                & .meeting-calendar-date-value {
                    margin-top: 5px;
                    text-align: start !important;
                    font-size: 16px;
                }

                & .meeting-calendar-date-content {
                    margin-top: 5px;

                    .schedule-dashboard-calendar {
                        height: 100%;
                        padding: 0 !important;
                        text-align: start !important;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 15px;

                        .corp-user-schedule {
                            background-color: #f2f7fb;
                            border: 0.5px solid #f2f7fb;

                            &:hover {
                                border-color: #197abd;
                            }

                            img {
                                margin-right: 2px;
                                vertical-align: baseline;
                            }
                        }

                        .specialists-schedule {
                            border: 0.5px solid transparent;

                            &:hover {
                                border-color: #109636;
                            }

                            img {
                                margin-right: 2px;
                                vertical-align: baseline;
                                opacity: 0;
                            }
                        }
                    }
                }

                & .meeting-calendar-date-content-edit {
                    margin-top: 5px;
                    width: 80%;
                    height: 100%;
                    border: 1px solid #d5dee4;
                    border-radius: 4px;

                    .schedule-dashboard-calendar-edit {
                        height: 100%;
                        padding: 0 !important;
                        text-align: center;
                        font-size: 10px;
                        line-height: 15px;

                        .user-edit-schedule {
                            margin-bottom: 0 !important;
                            padding: 0.25rem !important;
                            border-bottom: 1px solid #d5dee4;

                            &:last-child {
                                border-bottom: none;
                            }
                        }

                        .reserved-user-schedule {
                            background-color: #006cb6;
                            color: #ffffff;
                            font-weight: 700;
                        }

                        .presented-user-schedule {
                            background-color: #109636;
                            color: #ffffff;
                            font-weight: 700;
                        }

                        .available-user-schedule {
                            background-color: #ffffff;
                            color: #2e353a;
                            font-weight: 400;
                        }

                        .not-available-user-schedule {
                            background-color: #f4f5f5;
                            color: #848f97;
                            font-weight: 400;
                            cursor: not-allowed;
                        }
                    }
                }

                & .meeting-calendar-today {
                    background-color: #f8fade !important;
                }
            }
        }

        .edit-user-calendar {
            height: auto;
            padding: 16.5px;
            margin-top: 32px;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-align: center;
            border-radius: 27px !important;
            transition: 0.3s ease-in-out;

            &:hover {
                opacity: 0.7;
            }

            &.edit-button {
                width: 320px;
                background-color: #006cb6;
                color: #ffffff;
                border: 1px solid #006cb6;
            }

            &.cancel-button {
                width: 200px;
                background-color: #ffffff;
                color: #006cb6;
                border: 1px solid #006cb6;
                position: absolute;
                left: 0;
            }
        }

        .edit-calendar-button-group {
            position: relative;
            width: 100%;
            text-align: center;
        }
    }
    @media (max-width: 1440px) {
        &-calendar {
            &-info {
                .user-details-calendar-header {
                    .calendar-header-info {
                        .month-picker {
                            &-text {
                                font-size: 15px;
                                margin: 0 0.6rem;
                            }

                            .picker-arrow {
                                padding: 4px;
                            }
                        }

                        .schedule-meeting-count {
                            .count {
                                font-size: 10px;
                                margin-left: 10px;
                                margin-top: 3px;
                            }
                        }
                    }
                }
            }

            .edit-user-calendar {
                width: 200px !important;
                font-size: 14px;

                &.cancel-button {
                    position: relative;
                }
            }

            .edit-calendar-button-group {
                .edit-user-calendar {
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        &-calendar {
            &-info {
                .user-details-calendar-header .calendar-header-info .month-picker {
                    justify-content: left;
                }
            }
        }
    }
}

.edit-user-calendar-modal {
    width: 850px !important;

    .ant-modal-close {
        top: 30px;
        right: 30px;

        &-x {
            width: auto;
            height: auto;
            line-height: 20px;

            span {
                font-size: 32px !important;
            }
        }
    }

    .ant-modal-body {
        padding: 32px !important;

        .title-modal {
            color: #2e353a;
            font-size: 32px;
            font-weight: 700;
            line-height: 48px;
            text-align: center;
            margin-top: 30px;
        }

        .body-modal {
            margin: 40px 0;

            .added-date {
                display: flex;
                border-top: 1px solid #d4e1ea;

                &-title {
                    width: 30%;
                    padding: 15px;
                    background-color: #f2f7fb;
                    display: flex;
                    align-items: center;
                }

                &-desc {
                    padding: 15px 30px;

                    p {
                        color: #006cb6;
                    }
                }
            }

            .deleted-date {
                display: flex;
                border-top: 1px solid #d4e1ea;
                border-bottom: 1px solid #d4e1ea;

                &-title {
                    width: 30%;
                    padding: 15px;
                    background-color: #fdf6f4;
                    display: flex;
                    align-items: center;
                }

                &-desc {
                    padding: 15px 30px;

                    p {
                        color: #e95532;
                    }
                }
            }
        }

        .cancel-info {
            display: flex;
            border-top: 1px solid #d4e1ea;
            color: #2e353a;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;

            &-left {
                position: relative;

                &::before {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 1.5px;
                    height: 18px;
                    background-color: #cce1f0;
                    transform: translateY(-50%);
                    transition: background-color 0.3s;
                    content: '';
                }
            }
        }

        .footer-buttons {
            display: flex;

            .general-button {
                border-radius: 27px !important;
                height: auto;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
            }

            .cancel-button {
                width: 200px;
                padding: 16px;
                background: transparent;
                color: #006cb6;
                border: 1px solid #006cb6;
                transition: ease-in-out 0.4s;

                &:hover {
                    background: #006cb6;
                    color: #ffffff;
                    transition: ease-in-out 0.4s;
                }
            }

            .submit-button {
                width: 320px;
                padding: 16px;
                background: #006cb6;
                color: #ffffff;
                border: 1px solid #006cb6;
                transition: ease-in-out 0.4s;

                &:hover {
                    opacity: 0.7;
                    transition: ease-in-out 0.4s;
                }
            }
        }
    }
}

//update user confirmation modal
.update-user-confirmation-modal {
    width: 40% !important;

    .title {
        font-size: 32px;
        line-height: 48px;
        font-weight: 700;
        text-align: center;
        color: #2e353a;
        padding: 32px 16px;
    }

    .subtitle {
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
        text-align: center;
        color: #2e353a;
        text-align: center;
    }
}
