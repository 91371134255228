.user-details {
    .title {
        font-size: 22px;
    }

    .user-details-info {
        .desc {
            font-size: 12px;
        }
    }

    &-header {
        .calendar-options {
            font-size: 10px;
            min-width: 110px;
            padding: 6px;
            margin-right: 8px;
        }
    }

    &-calendar {
        .month-picker-text {
            font-size: 14px;
        }

        &-info {
            .user-details-calendar-header {
                .calendar-header-info {
                    height: auto;

                    .month-picker,
                    .schedule-meeting-count {
                        position: relative;
                        justify-content: center !important;
                    }
                }
            }
        }

        .edit-user-calendar {
            margin-top: 15px;
            padding: 10px;
            font-size: 12px;
        }
    }
}

.edit-user-calendar-modal {
    width: auto !important;

    .ant-modal-close {
        top: 15px;
        right: 15px;

        &-x {
            width: auto;
            height: auto;

            span {
                font-size: 16px !important;
            }
        }
    }

    .ant-modal-body {
        padding: 20px !important;

        .title-modal {
            font-size: 18px;
            line-height: 35px;
            margin-top: 10px;
        }

        .body-modal {
            margin: 0 0 20px;
            position: relative;
            overflow-x: scroll;

            .added-date {
                min-width: 300px;

                &-desc {
                    padding: 15px;
                    font-size: 12px;
                }
            }

            .deleted-date {
                min-width: 300px;

                &-desc {
                    padding: 15px;
                    font-size: 12px;
                }
            }
        }

        .footer-buttons {
            justify-content: space-evenly;
            .general-button {
                font-size: 12px;
                width: 125px;
            }
        }
    }
}
